<template>
  <div id="CommonInstitutional">
    <div class="top clearfix">
      <div class="pull-left"><span></span> 培训机构</div>
      <a-pagination 
        class="pull-right" 
        :total="total" 
        :current="current"
        :default-page-size="3"
        size="mini"
        @change="pageClick"
        simple/>
    </div>
    <div class="institution_list">
      <div class="institution_item" v-for="item in dataList" :key="item.id" @click="jiClick(item.id)">
        <img :src="baseUrl + item.logoUrl" />
        <div>
          <h5>{{ item.organizationName }}</h5>
          <p>课程数量 {{ item.courseNumber }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 10,
      current: 1,
      dataList: []
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   document.getElementsByTagName('input')[0].readOnly = true;
    // })
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.InstitutionList({
        pageNum: this.current,
        pageSize: 3
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.total = res.data.data.total;
        }
      })
    },
    pageClick(page) {
      this.current = page;
      this.showPage();
    },
    jiClick(id = 0) {
      window.open(`/institutionDetail/${id}`)
    },
  }
}
</script>

<style lang='scss'>
  #CommonInstitutional {
    padding: 10px 0;

    .top {
      padding: 15px 10px;

      & > div.pull-left {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: center;

        & > span {
          width: 8px;
          height: 20px;
          background: #05a081;
          border-radius: 2px;
          display: inline-block;
          margin-right: 15px;
        }
      }

      .arco-input-wrapper .arco-input.arco-input-size-medium {
        padding: 0;
        font-size: 12px;
      }

      .arco-pagination-simple .arco-pagination-jumper .arco-pagination-jumper-input {
        width: 18px;
        border: none;
        display: inline;
      }

      .arco-pagination-jumper-total-page {
        display: inline-block;
        width: 18px;
        text-align: center;
        margin-right: 0;
      }

      .arco-pagination-size-mini .arco-pagination-item {
        min-width: 20px;
        height: 20px;
        line-height: 20px;
      }

      .arco-pagination-item-previous {
        margin-right: 10px;
      }

      .arco-icon {
        font-size: 16px;
      }

      .arco-pagination-jumper-separator {
        padding: 0 5px;
      }

      .arco-pagination-simple .arco-pagination-jumper {
        display: none;
      }
    }

    .institution_list {
      .institution_item {
        width: 280px;
        height: 80px;
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #f7f7f7;
        }

        & > img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          margin-right: 12px;
          flex-shrink: 0;
        }

        & > div {
          font-size: 16px;
          width: calc(100% - 72px);
          flex-shrink: 0;

          & > p {
            font-size: 12px;
            color: #86909c;
            margin-top: 4px;
          }

        }
      }
    }
  }
</style>