<template>
  <div class="article">
    <div class="title">
      <h3>最新文章</h3>
      <a-divider direction="vertical" />
      <router-link :to="item.link" v-for="item in typeList" :key="item.title">{{ item.title }}</router-link>
    </div>
    <CommonArticleList :dataList="dataList"></CommonArticleList>
    <a-button v-if="dataList.length < totalNum " type="primary" @click="loginClick">加载更多…</a-button>
    <p v-else>已全部加载完毕</p>
  </div>
</template>

<script>
import CommonArticleList from '@/components/CommonArticleList.vue'
export default {
  components: {
    CommonArticleList
  },
  data() {
    return {
      dataList: [],
      typeList: [
        {title: '政策法规', link: '/policiesAndRegulations'},
        {title: '标准研制', link: '/standardDevelopment'},
        {title: '双碳示范', link: '/doubleCDemonstration'},
      ],
      pageNum: 0,
      totalNum: 0,
      search: false
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollClick)
  },
  mounted() {
    this.showPage();
    window.addEventListener('scroll', this.scrollClick)
  },
  methods: {
    showPage() {
      if(this.search) return;
      else this.search = true
      if(!this.$store.state.loginIn && this.pageNum >= 2) return;
      this.pageNum += 1;
      this.$API.ArticleList({
        pageNum: this.pageNum,
        pageSize: 10,
        state: 1,
        // articleClassificationId: this.$route.meta.index, //0-政策法规，1-标准研制，2-双碳示范
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.list.forEach(item => {
            item.traffic = 0;
            item.collection = 0;
          })
          this.dataList = [...this.dataList, ...res.data.data.list]
          this.totalNum = res.data.data.total; 
          this.showTotal();
          this.search = false;

        }
      })
    },
    showTotal() {
      this.$API.ArticleTotalList({
        pageNum: this.pageNum,
        pageSize: 10,
        state: 1,
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.forEach((item, index) => {
           this.dataList[index + ((this.pageNum - 1) * 10)].traffic = item.traffic;
           this.dataList[index + ((this.pageNum - 1) * 10)].collection = item.collection;
          })
        }
      })
    },
    scrollClick() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(document.body.clientHeight - scrollTop - document.documentElement.clientHeight < 400) {
        if(this.dataList.length < this.totalNum) this.showPage()
      }
    },
    loginClick() {
      this.$store.commit('open_type', 1)
    }
  }
}
</script>

<style lang='scss'>
  .article {

    .title {
      display: flex;
      align-items: center;
      padding: 10px;

      .arco-divider-vertical {
        margin: 0 20px;
      }

      h3 {
        font-size: 24px;
      }

      & > a {
        font-size: 16px;
        font-weight: 700;
        color: #1d2129;
        margin-right: 25px;
        text-decoration: none;

        &:hover {
          color: #05A081;
        }

      }
    }
    .arco-btn-primary {
      width: 100%;
      height: 50px;
    }

    & > p {
      text-align: center;
      color: #86909c;
      margin-top: 30px;
    }
  }
</style>