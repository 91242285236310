<template>
  <div id="banner">
     <a-carousel
        :style="{ width: '1180px', height: '380px' }"
        :auto-play="true"
        indicator-type="line"
        show-arrow="hover"
        :move-speed="1000"
      >
        <a-carousel-item v-for="item in images" :key="item.id">
          <img :class=" item.toLink ? 'pointer' : ''" @click="eventClick(item.toLink)" :src="baseUrl + item.url" :style="{ width: '100%' }" />
        </a-carousel-item>
      </a-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [],
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    showPage() {
      this.$API.CarouselList().then(res => {
        if(res.data.code == 200) {
          this.images = res.data.data 
        }
      })
    },
    eventClick(link) {
      if(!link) return;
      window.open(link)
    }
  }
}
</script>

<style lang='scss'>
  #banner {
    margin-bottom: 30px;
    padding: 0 10px;
    

    .arco-carousel-slide {
      border-radius: 5px;
    }

    .arco-carousel-indicator-wrapper-bottom {
      background: transparent;
    }
  }
</style>