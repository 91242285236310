<template>
  <div id="home">
    <Banner></Banner>
    <div class="common_layout">
      <div class="layout_left">
        <Article></Article>
      </div>
      <div class="layout_right">
        <div id="top" style="z-index: 4;position: relative;">
          <!-- <div class="new">
            <p>诚挚邀请您对平台进行满意度评价</p>
            <a-button type="outline" status="success" @click="eventClick('open')">前往评价</a-button>
          </div>
          <a-divider /> -->
          <div class="tip">
            <img src="../../assets/img/standard.png" />
            <a-button type="primary" @click="eventClick">查阅双碳标准信息库</a-button>
          </div>
          <CommonHotArticleList></CommonHotArticleList>
          <CommonInstitutional></CommonInstitutional>
        </div>
        <div :style="{opacity : show ? '100%' : '0'}" class="tip fixed">
          <img src="../../assets/img/standard.png" />
          <a-button type="primary" @click="eventClick">查阅双碳标准信息库</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from './components/Banner.vue'
import Article from './components/Article.vue'
import CommonInstitutional from '@/components/CommonInstitutional.vue'
import CommonHotArticleList from '@/components/CommonHotArticleList.vue'
export default {
  components: {
    Banner,
    Article,
    CommonInstitutional,
    CommonHotArticleList
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollClick)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollClick)
  },
  methods: {
    eventClick(type = null) {
      if(type == 'open') window.open('https://tgik6tfkz3.jiandaoyun.com/f/650943cf21e0f8269e2ba58e')
      else this.$router.push('/standardInformationBase/ALL')
    },
    scrollClick() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var menu = document.getElementById('top')
      if(scrollTop > menu.offsetHeight + menu.offsetTop - 94) {
        this.show = true;
      }else {
        this.show = false;
      }
    },

  }
}
</script>

<style lang='scss'>
  #home {
    width: 1200px;
    margin: 0 auto;

    .layout_right {
      .tip {
        text-align: center;
        padding: 20px 30px;
        width: 280px;

        img {
          width: 200px;
          height: 143px;
          margin-bottom: 30px;
        }

        .arco-btn-primary {
          width: 220px;
          height: 40px;
          border-radius: 4px;
          font-size: 14px;
        }

      }
    }

    .fixed {
      position: fixed;
      top: 104px;
      transition: all 0.2s;
    }

    .new {
      text-align: center;
      margin-top: 25px;

      p {
        font-size: 14px;
        color: #4e5969;
        line-height: 20px;
        margin-bottom: 15px;
        font-weight: 600;
      }

      .arco-btn {
        width: 220px;
        height: 40px;
      }
      
    }

    .arco-divider-horizontal {
      max-width: calc(100% - 20px);
      min-width: calc(100% - 20px);
      margin: 35px auto 15px;
    }
    
  }
</style>